import auth from './auth';
import user from './user';
import studies from './studies';
import generative from './generative';
import comparisons from './comparisons';
import billing from './billing';
import projects from './projects';
import appsumo from './appsumo';
import demo from './demo';
import feedback from './feedback';
import company from './company';

export default {
  auth,
  user,
  studies,
  generative,
  comparisons,
  billing,
  projects,
  appsumo,
  demo,
  feedback,
  company,
};
