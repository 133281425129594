/* eslint-disable no-param-reassign */
import {v1 as uuid} from 'uuid';
import get from 'lodash/get';
import {createReducer, createSelector} from '@reduxjs/toolkit';
import {
  comparisonTypes, annonationModes
} from './types';
import {
  setControlComparison,
  setSecondaryComparison,
  setIsLoading,
  clearCreation,
  setComparisonResultsData, setComparisonFeatureFlags,
} from './actions';
import {defaultAnnotationNames} from './config';
import {FEATURES_FLAGS} from '../../constants/features';

const createComparisonObject = (object = {}) => ({
  id: uuid(), // always exists
  type: comparisonTypes.none,
  image: null,
  resourceId: null, // exists if comparison is saven in database
  studyResourceId: null, // exists if image was originally from study
  studyId: undefined, // exists if image was originally from study
  controlResource: false,
  ...object,
});

const initialComparison = createComparisonObject();

export const initialState = {
  id: null,
  name: '',
  isLoading: false,
  // toggles
  showHeatmap: true,
  // TODO temporarily disabled
  showAOI: true,
  // comparisonItem
  comparisons: {},
  order: [],
  featuresFlags: FEATURES_FLAGS.HEATMAP | FEATURES_FLAGS.AOI,
  controlComparison: initialComparison.id,
  secondaryComparison: null,
  annotationsNames: defaultAnnotationNames,
};

const resetAnnotationMode = (comparisons, controlComparison) => {
  const newComparisons = {...comparisons};

  Object.keys(newComparisons).forEach((k) => {
    const isControl = controlComparison === newComparisons[k].id;

    newComparisons[k] = {
      ...newComparisons[k],
      annotationMode: annonationModes.initial,
      controlResource: isControl,
    };
  });

  return newComparisons;
};

const formComparisonData = (comparisonId, comparisons) => {
  // form comparisons
  const formedComparisons = {};

  for (let i = 0; i < comparisons.length; i++) {
    formedComparisons[comparisons[i].resourceId] = comparisons[i];
    formedComparisons[comparisons[i].resourceId].id = comparisons[i].resourceId;
  }

  return formedComparisons;
};

/**
 * Get last resource id that is marked as control image
 */
const getControlResourceId = (comparisons) => {
  let resourceId = '';

  Object.keys(comparisons).forEach((key) => {
    if (comparisons[key].controlResource === true) {
      resourceId = key;
    }
  });

  // if no image is marked as control, just mark first one
  if (resourceId === '') {
    resourceId = comparisons[Object.keys(comparisons)[0]].resourceId;
    // eslint-disable-next-line no-param-reassign
    comparisons[Object.keys(comparisons)[0]].controlResource = true;
  }

  return resourceId;
};

const getSecondaryResourceId = (comparisons) => {
  let resourceId = '';

  const lastNotControlComparison = Object.values(comparisons).reverse().find(
    (comparison) => !comparison.controlResource
  );

  if (lastNotControlComparison) {
    resourceId = lastNotControlComparison.resourceId;
  }

  return resourceId;
};

export default createReducer(initialState, {
  [setControlComparison]: (state, action) => {
    state.comparisons = resetAnnotationMode(state.comparisons, action.payload);
    state.secondaryComparison = state.controlComparison;
    state.controlComparison = action.payload;
  },
  [setSecondaryComparison]: (state, action) => {
    state.secondaryComparison = action.payload;
  },
  [setComparisonResultsData]: (state, action) => {
    const {comparisonId, comparisons, name} = action.payload;

    state.name = name;
    state.id = comparisonId;

    if (comparisons.length === 0) {
      return;
    }
    const formedComparisonsData = formComparisonData(comparisonId, comparisons);

    state.comparisons = formedComparisonsData;
    state.controlComparison = getControlResourceId(formedComparisonsData);
    state.secondaryComparison = getSecondaryResourceId(formedComparisonsData);
    state.order = Object.keys(formedComparisonsData);
  },
  [setIsLoading]: (state, action) => {
    state.isLoading = action.payload.isLoading;
  },
  [setComparisonFeatureFlags]: (state, action) => {
    state.featuresFlags = action.payload;
  },
  [clearCreation]: () => initialState,
});

const _getCreateComparison = (state) => state?.createComparison;

export const selectComparisonFeatureFlags = createSelector(
  _getCreateComparison,
  (state) => state?.featuresFlags,
);

export const selectComparisonFocusMapAvailability = createSelector(
  _getCreateComparison,
  (state) => {
    const {controlComparison, comparisons, secondaryComparison} = state;

    const controlFocus = get(comparisons, `${controlComparison}.image.focus_image`, null);

    const secondaryFocus = get(comparisons, `${secondaryComparison}.image.focus_image`, null);

    return Boolean(controlFocus && secondaryFocus);
  }
);
