export const CLARITY_BREAKPOINTS = {
  WEB: 1.6,
  MOBILE: 0.45,
};

export const FOLD_RATIO = {
  WEB: 1.6,
  web: 1.6,
  MOBILE: 0.45,
  mobile: 0.45,
};

export const HUNDRED = 100;
