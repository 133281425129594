import {
  DESKTOP, GENERAL, MOBILE, POSTER
} from './analysisTypes';

export const FEATURES = {
  CREDITS: 'designs',
  SEATS: 'user_seats',
  VIDEO_CREDITS: 'video_credits',
  RECOMMENDATIONS: 'recommendations',
  SHARE_ANALYSIS: 'share_analysis',
  SHARE_TEAM: 'team',
  PDF_REPORT: 'report',
  PPT_REPORT: 'report_ppt',
  FOCUS_MAP: 'focus_map',
  CONTRAST_MAP: 'contrast_map',
  GENERATIVE_RECOMMENDATIONS: 'generative_recommendations',
  WATERMAK: 'logo',
  BUY_SEATS: 'buy_seats',
  FULL_PAGE: 'full_page',
  API: 'transactions',
  STORAGE_TIME: 'storage_custom',
};

export const FEATURES_FLAGS = {
  HEATMAP: 0b0001,
  AOI: 0b0010,
  FOCUS: 0b0100,
  NONE: 0b0000,
  CONTRAST: 0b1000
};

export const FEATURES_MAP = {
  NONE: 0,
  HEATMAP: 1,
  FOCUS: 2,
  CONTRAST: 3,
  AOI: 4,
};

export const CONTRAST_SUPPORTED_TYPES = [DESKTOP, GENERAL, MOBILE, POSTER];
