import {Component} from 'react';

// TODO ADD EXPECTION PAGE, COMPONENT

class ErrorBoundry extends Component {
  // state = {error: null, errorInfo: null};
  //
  // componentDidCatch (error, errorInfo) {
  //   this.setState({error, errorInfo});
  // }

  render () {
    // const {errorInfo, error} = this.state;
    const errorInfo = false;

    if (errorInfo) {
      return (
        // <Exception
        //   type="500"
        //   renderDescription={() => 'Sorry, something went wrong'}
        //   message={error && error.toString()}
        //   stack={errorInfo.componentStack}
        // />
        <p>
          something went wrong
        </p>
      );
    }

    return this.props.children;
  }
}

const withErrorBoundry = (WrappedComponent) => {
  return (props) => (
    <ErrorBoundry>
      <WrappedComponent {...props} />
    </ErrorBoundry>
  );
};

export default withErrorBoundry;
