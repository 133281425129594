import {useEffect, useRef} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {useIntercom} from 'react-use-intercom';

import {isBlacklistedUrl} from '../../helpers/router';
import {getIntercomProfileSelector} from '../../reducers/settings';

const IntercomWithData = ({userData}) => {
  const {
    boot, shutdown, update
  } = useIntercom();

  const isBooted = useRef(false);

  useEffect(() => {
    if (!isBooted.current && userData && userData.user_id) {
      boot({customAttributes: {...userData}});
      isBooted.current = true;
    }
  }, [userData]);

  useEffect(() => {
    if (isBooted.current && userData && userData.user_id) {
      update({customAttributes: {...userData}});
    }
  }, [userData]);

  useEffect(() => shutdown, []);

  return null;
};

const IntercomWithoutData = () => {
  const {
    boot, shutdown
  } = useIntercom();

  useEffect(() => {
    boot();

    return () => {shutdown();};
  }, []);

  return null;
};

const IntercomInterface = ({userData, isAuthenticated, location}) => {
  const isDisallowedPath = isBlacklistedUrl(location.pathname);

  if (isDisallowedPath) return null;

  if (isAuthenticated) {
    return <IntercomWithData key="IntercomWithData" userData={userData} />;
  }

  return <IntercomWithoutData key="IntercomWithoutData" />;
};

const mapStateToProps = (state) => ({
  userData: getIntercomProfileSelector(state),
});

const enhance = compose(withRouter, connect(mapStateToProps));

export default enhance(IntercomInterface);
