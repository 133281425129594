import {toast as toaster} from 'react-toastify';
// import success from '../../assets/images/success.svg';
// import error from '../../assets/images/error.svg';
// import warning from '../../assets/images/warning.svg';

export const toast = {
  success (message) {
    toaster.success(
      <>
        {/* <img src={success} alt="Success icon" /> */}
        <div>
          <h3>Success</h3>
          <p>{message}</p>
        </div>
      </>,
      {autoClose: 3000});
  },
  error (message) {
    toaster.error(
      <>
        {/* <img src={error} alt="Error icon" /> */}
        <div>
          <h3>Error</h3>
          <p>{message}</p>
        </div>
      </>,
      {autoClose: 8000});
  },
  warning (message) {
    toaster.warning(
      <>
        {/* <img src={warning} alt="Warning icon" /> */}
        <div>
          <h3>Warning</h3>
          <p>{message}</p>
        </div>
      </>,
      {autoClose: 4000});
  }
};
