import {createAction, createReducer} from '@reduxjs/toolkit';

export const setSearch = createAction('HEADER/SET_NAVIGATION');

const initialState = {
  search: false,
};

export default createReducer(initialState, {
  [setSearch]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.navigation = action.payload;
  },
});
