import {configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {ALWAYS_ALLOWED_ACTION_TYPES} from '../../constants/alwaysAllowedActionTypes';
import {hasTokensStored} from '../../helpers/auth';

import rootReducer from '../../reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
};

const checkIfNotLoggedOut = () => (next) => (action) => {
  if (!hasTokensStored()) {
    if (ALWAYS_ALLOWED_ACTION_TYPES.some((type) => action.type.includes(type))) {
      next(action);
    }

    return;
  }
  next(action);
};

const reducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(checkIfNotLoggedOut),
});

export default store;

export const persistor = persistStore(store);
