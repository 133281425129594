export const AUTH = '/auth';
export const LOGIN = '/auth/signin';
export const LOGOUT = '/auth/logout';
export const REMIND_PASSWORD = '/auth/forgot';
export const CONFIRMATION = '/auth/verify_email';
export const ADDITIONAL_INFO = '/auth/additional_info';
export const CONFIRMATION_WITH_TOKEN = '/auth/verify_email/:token';
export const CHANGE_PASSWORD = '/auth/reset/:token';
export const SIGN_UP = '/auth/signup';
export const APPSUMO = '/auth/appsumo';
export const APPSUMO_WITH_CODE = '/auth/appsumo/:code';
export const DEMO = '/auth/demo';
export const FACEBOOK_CALLBACK = '/auth/fb_callback';
export const GOOGLE_CALLBACK = '/auth/google-callback';
export const SOCIAL_LOGIN_COMPLETE = '/auth/login-complete';
export const DASHBOARD = '/dashboard';
export const PROJECTS = '/projects';
export const PROJECT_ANALYSES = '/projects/:id';
export const MY_ANALYSIS = '/analyses';
export const NEW_ANALYSIS = '/new-analysis';
export const NEW_ANALYSIS_UPLOAD = '/new-analysis/upload';

export const MY_VIDEOS = '/videos';
export const NEW_VIDEO_ANALYSIS = '/new-video';
export const NEW_VIDEO_UPLOAD = '/new-video/upload';
export const VIDEO_REQUEST = '/video-request';

export const COMPARISONS = '/comparisons';
export const SINGLE_COMPARISON = '/comparisons/:id';
export const ACCOUNT = '/account';
export const ACCOUNT_SUBSCRIPTION = '/account/subscription';
export const ACCOUNT_BILLING = '/account/billing';
export const ACCOUNT_SETTINGS = '/account/settings';
export const PRIVACY_POLICY = 'https://attentioninsight.com/privacy-policy/';

export const SINGLE_ANALYSIS = '/single-analysis/:id';
export const SINGLE_ANALYSIS_SHARED = '/single-analysis/share/:id';

export const SINGLE_VIDEO_ANALYSIS = '/single-video-analysis/:id';
export const SINGLE_VIDEO_ANALYSIS_SHARED = '/single-video-analysis/share/:id';

export const START = '/start';
