export const annonationModes = {
  applied: 'applied', // uses control image annonations
  denied: 'denied', // uses own annonations
  initial: undefined, // show annonation screen
};

export const comparisonTypes = {
  study: 'study',
  none: undefined,
};
