/* eslint-disable no-param-reassign */
import {createAction, createReducer, createSelector} from '@reduxjs/toolkit';
import get from 'lodash/get';
import {getUrlWithToken, parseFirstError} from '../../helpers/auth';
import {toast} from '../../utils/toast';
import Api from '../../services/api';
import {_getStudyDetails} from '../studies';
import {getSelectedCompany} from '../settings';
import {getImageUrlFromPusher} from '../../services/websocket';
import {getPreloadedImageCanvas, batchify} from '../../components/Reports/helpers';

export const setProjectReportData = createAction('REPORTS/SET_PROJECT_DATA');
export const setProjectReportInfo = createAction('REPORTS/SET_PROJECT_INFO');
export const openReportModal = createAction('REPORTS/OPEN_REPORT_MODAL');
export const setProjectReportLoadingState = createAction('REPORTS/SET_PROJECT_LOADING_STATE');
export const setProjectReportSelectorData = createAction('REPORTS/SET_SELECTOR_DATA');
export const resetReportsStore = createAction('REPORTS/RESET');
export const setReportsImages = createAction('REPORTS/SET_IMAGES');
export const startReportImageGeneration = createAction('REPORTS/START_IMAGES_GENERATION');

const FILTERS = {
  page: 0,
  sortBy: 'created_at:desc',
  items_per_page: 99999
};

const MAX_CONCURRENT_REQUESTS = 5;

export const initialState = {
  projectReport: {
    isOpen: false,
    isLoading: false,
    project: {
      id: null,
      name: null
    },
    data: [],

    selectorData: null
  },
  isModalOpen: false,
  images: {
    heatmapsAoi: {},
    heatmaps: {},
    heatmapsAoiSizes: {}
  },
  isImageGenerating: false
};

export default createReducer(initialState, {
  [setProjectReportInfo]: (state, action) => {
    const {id, name} = action.payload;

    state.projectReport.project.id = id;
    state.projectReport.project.name = name;
    state.projectReport.isOpen = !!id;
  },
  [openReportModal]: (state) => {
    state.isModalOpen = true;
  },
  [setProjectReportSelectorData]: (state, action) => {
    state.projectReport.selectorData = action.payload;
  },
  [setProjectReportData]: (state, action) => {
    state.projectReport.data = action.payload;
  },
  [setProjectReportLoadingState]: (state, action) => {
    state.projectReport.isLoading = action.payload;
  },
  [setReportsImages]: (state, action) => {
    state.images = action.payload;
  },
  [startReportImageGeneration]: (state) => {
    state.isImageGenerating = true;
  },
  [resetReportsStore]: () => {
    return initialState;
  }
});

export const getProjectReportAnalysisList = (projectId) => async (dispatch, getState) => {
  try {
    const company = getSelectedCompany(getState());

    if (!company) {
      return;
    }

    const {id: companyId} = company;

    const {data} = await Api.projects.getById({companyId, projectId, filters: FILTERS});

    dispatch(setProjectReportSelectorData(data.data));
  } catch (err) {
    if (err) {
      // dispatch(resetReportsStore());
      toast.error('Couldn\'t fetch your project data');
    }
  }
};

export const getReportData = (ids) => async (dispatch) => {
  dispatch(setProjectReportLoadingState(true));

  try {
    const dataPromises = ids.map((id) => Api.studies.getById(id));

    const allData = await Promise.all(dataPromises);

    const payload = allData.map(({data}) => {
      if (typeof data.meta.study.benchmark.benchmark !== 'undefined') {
        data.meta.study.benchmark.benchmark = JSON.parse(data.meta.study.benchmark.benchmark);
      }

      return data;
    });

    dispatch(setProjectReportData(payload));
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t get data');

    toast.error(error);
  } finally {
    dispatch(setProjectReportLoadingState(false));
  }
};

export const getReportImages = (urls) => async (dispatch) => {
  try {
    dispatch(startReportImageGeneration());

    const images = {
      heatmapsAoi: {},
      heatmapsAoiSizes: {},
      heatmaps: {}
    };

    const urlEntries = Object.entries(urls);

    const batches = batchify(urlEntries, MAX_CONCURRENT_REQUESTS); // Batch size of 10

    await batches.reduce(async (prevPromise, batch) => {
      await prevPromise;
      const result = await processBatch(batch);

      result.forEach(({
        id, heatmap, heatmapAoi, heatmapsAoiSizes
      }) => {
        images.heatmaps[id] = getUrlWithToken(heatmap);
        images.heatmapsAoi[id] = getUrlWithToken(heatmapAoi);
        images.heatmapsAoiSizes[id] = heatmapsAoiSizes;
      });
    }, Promise.resolve());

    dispatch(setReportsImages(images));
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t generate pdf');

    toast.error(error);
    // dispatch(resetReportsStore);
  }
};

async function processBatch (batch) {
  const imagePromises = [];

  batch.forEach(([id, {heatmap, heatmapAoi}]) => {
    const imagePromise = Promise.all([
      // getImageUrlFromPusher(heatmap),
      getImageUrlFromPusher(heatmap),
      getImageUrlFromPusher(heatmapAoi)
    ])
      .then(([heatmapUrl, heatmapAoiUrl]) => {
        return {
          id,
          heatmap: heatmapUrl,
          heatmapAoi: heatmapAoiUrl
        };
      })
      .then(async (reportImageObject) => {
        const [, imageElement] = await getPreloadedImageCanvas(
          getUrlWithToken(reportImageObject.heatmapAoi)
        );

        return {
          ...reportImageObject,
          heatmapsAoiSizes: {
            height: imageElement.naturalHeight,
            width: imageElement.naturalWidth
          }
        };
      });

    imagePromises.push(imagePromise);
  });

  return Promise.all(imagePromises);
}

export const _selectReportsState = (state) => state?.reports;

export const _selectProjectReport = (state) => _selectReportsState(state)?.projectReport;

export const selectProjectReportLoadingState = createSelector(
  _selectProjectReport,
  (projectReport) => get(projectReport, 'isLoading', false)
);

// eslint-disable-next-line max-len
export const selectProjectReportInfo = createSelector(_selectProjectReport, (projectReport) => get(projectReport, 'project', {}));

// eslint-disable-next-line max-len
export const selectProjectReportStatus = createSelector(_selectProjectReport, (projectReport) => get(projectReport, 'isOpen', false));

// eslint-disable-next-line max-len
export const selectProjectReportData = createSelector(_selectProjectReport, (projectReport) => get(projectReport, 'data', []).map(_getStudyDetails));

export const selectProjectReportSelectorData = createSelector(
  _selectProjectReport,
  (projectReport) => get(projectReport, 'selectorData', null)
);

export const selectReportModalOpenStatus = createSelector(
  _selectReportsState,
  _selectProjectReport,
  (reports, projectReport) => reports?.isModalOpen || get(projectReport, 'isOpen', false)
);

export const selectReportImages = createSelector(_selectReportsState, (reports) => reports?.images);
