import {isEmpty} from 'lodash';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Route, Redirect} from 'react-router-dom';

import {hasTokensStored} from '../auth';
import withErrorBoundry from './with-error-boundry';

const ProtectedRoute = ({
  component: Component, isAuthenticated, componentProps, ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isAuthenticated) {
        return <Redirect to={{pathname: '/auth/signin', state: {from: props.location}}} />;
      }

      return <Component {...props} {...componentProps} />;
    }}
  />
);

const mapStateToProps = (state) => ({
  isAuthenticated: !isEmpty(state.settings.profile.data) && hasTokensStored()
});

const enhance = compose(
  withErrorBoundry,
  connect(mapStateToProps)
);

export default enhance(ProtectedRoute);
