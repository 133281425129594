import {createAction} from '@reduxjs/toolkit';
import {toast} from '../../utils/toast';
import api from '../../services/api';
import {parseFirstError} from '../../helpers/auth';

const KEY = 'CREATE_COMPARISONS';

export const setControlComparison = createAction(`${KEY}/SET_CONTROL_COMPARISON`);
export const setSecondaryComparison = createAction(`${KEY}/SET_SECONDARY_COMPARISON`);
export const setIsLoading = createAction(`${KEY}/SET_IS_LOADING`);
export const clearCreation = createAction(`${KEY}/CLEAR_CREATION`);
export const setComparisonResultsData = createAction(`${KEY}/SET_RESULTS_DATA`);
export const setComparisonFeatureFlags = createAction(`${KEY}/SET_FEATURE_FLAGS`);

export const getComparison = ({comparisonId}) => async (dispatch) => {
  try {
    /* Set loading state */
    dispatch(setIsLoading(true));

    /* Get edit study data */
    const {data} = await api.comparisons.getComparison(comparisonId);

    dispatch(
      setComparisonResultsData({
        comparisonId,
        comparisons: data.data,
        name: data.name,
      }),
    );
  } catch (err) {
    const error = parseFirstError(err, `Couldn't get edit data for comparison #${comparisonId}`);

    toast.error(error);
  } finally {
    dispatch(setIsLoading(false));
  }
};
