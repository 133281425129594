import {Userpilot} from 'userpilot';
import {FOLD_RATIO} from '../../constants/breakpoints';

export const CUSTOM_EVENTS = {
  CREATED_PROJECT: 'Created project (click)',
  CREATED_ANALYSIS: 'Created Analysis (click)',
  CREATED_FEW_ANALYSES: 'Created multiple analyses at once (more than 1 image) (click)',
  CREATED_ANALYSIS_DESKTOP_SHORT: 'Created Analysis (Desktop + screen-sized) (click)',
  CREATED_ANALYSIS_DESKTOP_LONG: 'Created Analysis (Desktop Full Page) (click)',
  CREATED_ANALYSIS_DESKTOP_URL: 'Created Analysis (Desktop + Capture URL) (click)',
  CREATED_ANALYSIS_MOBILE_SHORT: 'Created Analysis (Mobile + screen-sized) (click)',
  CREATED_ANALYSIS_MOBILE_LONG: 'Created Analysis (Mobile Full Page) (click)',
  CREATED_ANALYSIS_MOBILE_URL: 'Created Analysis (Mobile + Capture URL) (click)',
  CREATED_ANALYSIS_MM: 'Created Analysis (Marketing Material) (click)',
  CREATED_COMPARISON: 'Created Comparison (click)',
  UPLOADED_IMAGE: 'Uploaded image',
  CAPTURED_URL: 'Captured URL (any) (click)',
  DREW_AOI: 'Drew AOI',
  AUTO_AOI_NOT_GENERATED: 'Auto-AOI is not generated',
  FOCUS_MAP_ANALYSIS_BUTTON: 'Clicked "Focus map" toggle button in analysis view',
  CONTRAST_MAP_ANALYSIS_BUTTON: 'Clicked "Contrast map" toggle button in analysis view',
  FOCUS_MAP_COMPARISON_BUTTON: 'Clicked "Focus map" toggle button in comparison view',
  COMPARISON_ADD_ELEMENT: 'Clicked "Add element" in comparison view',
  CHANGE_BENCHMARK_TYPE: 'Clicked on any benchmark type in dropdown',
  DOWNLOADED_ANALYSIS_REPORT: 'Downloaded analysis report (any) (click)',
  DOWNLOADED_PROJECT_REPORT: 'Downloaded project report (any) (click)',
  SUBSCRIBED_SOLO: 'Subscribed to a Solo plan (Clicked "Pay Now")',
  SUBSCRIBED_SMALL_TEAM: 'Subscribed to a Small Team plan (Clicked "Pay Now")',
  SUBSCRIBED_TEAM: 'Subscribed to a Team plan (Clicked "Pay Now")',
  SUBSCRIBED_BASIC: 'Subscribed to a Basic plan (Clicked "Pay Now")',
  SUBSCRIBED_PRO: 'Subscribed to a Pro plan (Clicked "Pay Now")',
  SUBSCRIBED_HERO: 'Subscribed to a Hero plan (Clicked "Pay Now")',
};

export const triggerAnalysisCreationCustomEvent = (analysisType, imageRef) => {
  const ratio = imageRef ? imageRef.naturalWidth / imageRef.naturalHeight : null;

  switch (analysisType) {
    case 'general': {
      return triggerCustomEvent(CUSTOM_EVENTS.CREATED_ANALYSIS_MM);
    }

    case 'mobile': {
      if (ratio && FOLD_RATIO[analysisType] && ratio < FOLD_RATIO[analysisType]) {
        return triggerCustomEvent(CUSTOM_EVENTS.CREATED_ANALYSIS_MOBILE_LONG);
      }

      return triggerCustomEvent(CUSTOM_EVENTS.CREATED_ANALYSIS_MOBILE_SHORT);
    }

    default: {
      if (ratio && FOLD_RATIO[analysisType] && ratio < FOLD_RATIO[analysisType]) {
        return triggerCustomEvent(CUSTOM_EVENTS.CREATED_ANALYSIS_DESKTOP_LONG);
      }

      return triggerCustomEvent(CUSTOM_EVENTS.CREATED_ANALYSIS_DESKTOP_SHORT);
    }
  }
};

export const triggerCustomEvent = (eventName) => {
  Userpilot.track(eventName);
};
