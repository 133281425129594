import axios from '../../helpers/axios';

const defaults = {
  grant_type: 'password',
  client_param: process.env.REACT_APP_CLIENT_PARAM || 'web',
};

export default {
  login: (payload) => axios.post('/oauth/token', {
    ...defaults,
    ...payload,
  }),
  refreshToken: () => axios.post('/oauth/token', {
    ...defaults,
    refresh_token: localStorage.getItem('refresh_token'),
    grant_type: 'refresh_token',
  }),
  logout: () => axios.get('/api/logout'),
  signup: (payload) => axios.post('/api/register', {...payload, api: '1'}),
  signupUpdate: (payload) => axios.post('/api/registration', {...payload}),
  appsumoSignup: (payload) => axios.post('/api/appsumo_register', {...payload, api: '1'}),
  verifyEmail: ({token, email}) => axios.get(
    `/api/email-verification/check/${token}`, {params: {email}}
  ),
  forgotPassword: ({email}) => axios.post('/api/password/email', {email}),
  resendEmail: () => axios.get('/api/resend-email'),
  resetPassword: ({token, email, password}) => axios.post('/api/password/reset', {
    token,
    email,
    password,
    password_confirmation: password,
  }),
  fbApiLoginCallback: ({
    token, grant_type, provider, client_param, session_id
  }) => axios.post('/api/socialLoginCallback', {
    grant_type,
    access_token: token,
    client_param,
    provider,
    session_id,
  }),
  gApiLoginCallback: ({
    token, grant_type, provider, client_param, session_id
  }) => axios.post('/api/socialLoginCallback', {
    grant_type,
    access_token: token,
    client_param,
    provider,
    session_id,
  }),
};
