import axios from '../../helpers/axios';

export default {
  getPlans: () => axios.get('/api/billing/plans'),
  getPlan: (planId) => axios.get(`/api/billing/plans/${planId}`),
  getFeatures: () => axios.get('/api/billing/features'),
  getCurrentSubscription: () => axios.get('/api/billing/subscriptions/current'),
  getInvoices: ({starting_after}) => axios.get('/api/billing/invoices', {
    params: {
      starting_after,
    },
  }),
  subscribe: ({
    planId,
    stripeToken,
    couponId,
    couponCode,
    companyName,
    taxType,
    taxID,
    address,
    numSeats,
  }) => axios.post('/api/billing/subscriptions/subscribe', {
    plan_id: planId,
    stripe_token: stripeToken,
    coupon_id: couponId,
    coupon_code: couponCode,
    company_name: companyName,
    tax_type: taxType,
    tax_id: taxID,
    address,
    quantity: numSeats
  }),
  checkPromoCode: ({
    promoCode,
    planAmount,
    planCurrency,
    slug,
    interval,
    planId,
  }) => axios.post('/api/billing/subscriptions/promo', {
    promo_code: promoCode,
    plan_price: planAmount,
    plan_currency: planCurrency,
    plan_code: slug,
    plan_interval: interval,
    stripe_plan_id: planId
  }),
  swap: () => axios.post('/api/billing/subscriptions/swap'),
  upgrade: ({planId, numSeats, interval}) => axios.post('/api/billing/subscriptions/upgrade', {
    plan_id: planId,
    quantity: numSeats,
    interval,
  }),
  checkout: ({
    planId, numSeats, promoCode, currency
  }) => axios.post('/api/billing/subscriptions/checkout', {
    plan_id: planId,
    quantity: numSeats,
    promo_code: promoCode,
    currency,
  }),
  cancelSubscription: ({reason}) => axios.post('/api/billing/subscriptions/cancel', {
    reason,
  }),

  changeCard: ({token}) => axios.put('/api/billing/subscriptions/card', {
    token,
  }),

  updateBilling: ({
    company_name, tax_id, tax_type, address
  }) => axios.put('/api/profile/billing', {
    company_name,
    tax_id,
    tax_type,
    address: {
      city: address.city,
      country: address.country,
      line1: address.line1,
      line2: address.line2,
      postal_code: address.postal_code,
      state: address.state,
    },
  }),
};
