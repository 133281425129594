import {createSelector} from '@reduxjs/toolkit';

const _getStudiesList = (state) => state?.studies?.list;

const _getList = (state) => state?.comparisons?.list;

const _getFullList = (state) => state?.comparisons?.fullList;

export const getListSelector = createSelector(
  _getList,
  _getStudiesList,
  (list = {}, studies = {}) => ({
    ...list,
    studiesCount: studies.data.length,
    isLoading: studies.isLoading || list?.isLoading,
  }),
);

export const getFullListSelector = createSelector(
  _getFullList,
  _getStudiesList,
  (fullList = {}, studies = {}) => ({
    ...fullList,
    studiesCount: studies.data.length,
    isLoading: studies.isLoading || fullList?.isLoading,
    fullListComparisons: fullList.comparisons
  }),
);
