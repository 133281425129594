import {actionTypes} from './types';

export const initialState = {
  byId: {}
};

// eslint-disable-next-line default-param-last
const studiesByIdReducer = (state = initialState, {type, payload},) => {
  switch (type) {
    case actionTypes.fetchStudy: {
      const {byId} = state;

      const {id} = payload;

      const study = byId[id];

      const _studies = {...byId, [id]: {...study, isLoading: true}};

      return {...state, byId: _studies};
    }
    case actionTypes.setStudy: {
      const {byId} = state;

      const {study} = payload;

      return {
        ...state,
        byId: {...byId, [payload.study.id]: {...study, isLoading: false}}
      };
    }
    default:
      return state;
  }
};

export default studiesByIdReducer;
