import {createAction} from '@reduxjs/toolkit';
import {toast} from '../../../utils/toast';
import Api from '../../../services/api';
import {parseFirstError} from '../../../helpers/auth';
import {getSelectedCompany} from '../../settings';

const KEY = 'COMPARISONS';

export const setComparisons = createAction(`${KEY}/SET_COMPARISONS`);
export const setComparisonsFullList = createAction(`${KEY}/SET_COMPARISONS_FULL_LIST`);
export const toggleFetching = createAction(`${KEY}/TOGGLE_FETCHING`);
export const setListFilters = createAction(`${KEY}/SET_LIST_FILTERS`);
export const setCreationData = createAction(`${KEY}/SET_CREATION_DATA`);
export const clearCreationData = createAction(`${KEY}/CLEAR_CREATION_DATA`);
export const setComparisonMap = createAction(`${KEY}/SET_COMPARISON_MAP`);
export const showComparisonAoisLayer = createAction(`${KEY}/SHOW_COMPARISON_AOIS_LAYER`);

export const getAll = ({filters}) => async (dispatch, getState) => {
  const {id: companyId} = getSelectedCompany(getState());

  try {
    dispatch(toggleFetching({key: 'list', value: 'isLoading', toggle: true}));

    const {data} = await Api.comparisons.getList({companyId, filters});

    dispatch(setComparisons(data));
    dispatch(setListFilters(filters));
  } catch (err) {
    toast.error('There was an error trying to fetch comparisons list');
  } finally {
    dispatch(toggleFetching({key: 'list', value: 'isLoading', toggle: false}));
  }
};

export const getFullList = ({filters}) => async (dispatch, getState) => {
  const {id: companyId} = getSelectedCompany(getState());

  try {
    dispatch(toggleFetching({key: 'fullList', value: 'isLoading', toggle: true}));

    const {data} = await Api.comparisons.getList({companyId, filters});

    dispatch(setComparisonsFullList(data));
  } catch (err) {
    toast.error('There was an error trying to fetch comparisons list');
  } finally {
    dispatch(toggleFetching({key: 'fullList', value: 'isLoading', toggle: false}));
  }
};

export const create = (name, studies) => async (dispatch, getState) => {
  try {
    const {id: companyId} = getSelectedCompany(getState());

    /* Set loading state */
    dispatch(toggleFetching({key: 'creation', value: 'isLoading', toggle: true}));

    /* Create study */
    const {data} = await Api.comparisons.create({companyId, studies, name});

    if (data && data.success === false) {
      throw new Error({response: {data}});
    } else {
      dispatch(setCreationData({id: data.studyId, name}));

      return data.studyId;
    }
  } catch (err) {
    const error = parseFirstError(err, 'Couldn\'t create new comparison.');

    toast.error(error);
    throw (err);
  } finally {
    dispatch(toggleFetching({key: 'creation', value: 'isLoading', toggle: false}));
  }
};

export const save = ({id, name, studies}) => async (dispatch) => {
  try {
    // dispatch(setIsLoading(true));
    dispatch(toggleFetching({key: 'saving', value: 'isLoading', toggle: true}));

    let data = null;

    if (studies) {
      data = await Api.comparisons.save({
        id,
        name,
        studies,
      });
    } else {
      data = await Api.comparisons.save({id, name});
    }

    if (data && data.success === false) {
      throw new Error({response: {data}});
    } else {
      dispatch(setCreationData({id: data.studyId, name}));

      return data.studyId;
    }
  } catch (e) {
    toast.error('Comparison could not be updated');
  } finally {
    dispatch(toggleFetching({key: 'saving', value: 'isLoading', toggle: false}));
  }
};

export const deleteById = (comparisonId) => async (dispatch, getState) => {
  try {
    /* Delete comparison by id */
    await Api.comparisons.deleteById(comparisonId);

    const {filters} = getState().comparisons.list;

    /* Refetch comparisons */
    dispatch(getAll({filters}));
  } catch (err) {
    const error = parseFirstError(err, `Couldn't delete comparison #${comparisonId}.`);

    toast.error(error);
  }
};
