import axios from '../../helpers/axios';

export default {
  sendFeedback: ({
    type, referenceId, reaction, feedback
  }) =>
    axios.post('/api/feedback', {
      type,
      reference_id: referenceId,
      reaction,
      feedback
    }),
};
