const TWO = 2;

export const getCookieValue = (name) => {
  const value = `; ${document.cookie}`;

  const parts = value.split(`; ${name}=`);

  if (parts.length === TWO) return parts.pop().split(';').shift();
};

export const getParneroParner = () => {
  return getCookieValue('partnero_partner') ?? null;
};
