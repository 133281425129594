import {isString, isArray, get} from 'lodash';
import {EMAILS_BLACK_LIST} from '../../constants/emails';

export const hasTokensStored = () => localStorage.getItem('token')
  && localStorage.getItem('refresh_token');

export const getCorrelationId = () => localStorage.getItem('correlationId')
  && localStorage.getItem('correlationId');

export const getUrlWithToken = (url) => {
  if (!isString(url)) {
    return;
  }

  const token = localStorage.getItem('token');

  const prefix = url.includes('?') ? '&' : '?';

  // NOTE: this is only for development to avoid CORS issues
  url.replace(':8081', ':3000');

  return `${url}${prefix}access_token=${token}`;
};

export const isUrlWhitelisted = () => {
  const whitelistedUrls = [
    '/single-analysis/share/',
    '/single-video-analysis/share/',
    'appsumo',
    '404',
    '403',
    '500',
  ];

  return whitelistedUrls.reduce((list, item) => {
    return list || window.location.pathname.includes(item);
  }, false);
};

export const parseFirstError = (err, defaultError) => {
  try {
    const {errors} = err.response.data;

    const keys = Object.keys(errors);

    const e = get(errors, `[${keys[0]}]`, defaultError);

    return isArray(e) ? e[0] : e;
  } catch {
    return defaultError;
  }
};

export const determineImage = (isNotValid, wasTouched, isActive, value) => {
  let imageType = 'neutral';

  if (!value) {
    return imageType;
  }

  if (isActive || value.trim() !== '') {
    imageType = 'blue';
  }

  if (wasTouched && isNotValid) {
    imageType = 'red';
  }

  return imageType;
};

export const determineNotCompanyEmail = (email = '') => {
  const emailDomain = /[^@]*$/.exec(email);

  const [domain] = emailDomain || [];

  return EMAILS_BLACK_LIST.has(domain);
};

export const determinePasswordStrength = (password) => {
  const minimumPasswordLength = 7;

  const minimumStrongPasswordLength = 10;

  const hasCapitalLetter =
      (value) => value.toLowerCase() !== value;

  const hasMinLengthOf = (value, minLength) => value.length >= minLength;

  const hasNumber = (value) => /\d/.test(value);

  const hasSpecialCharacters = (value) => /[!#@$%^&*)(+=._-]/.test(value);

  let strength = 'Weak';

  if (
    hasCapitalLetter(password) &&
    hasMinLengthOf(password, minimumPasswordLength) &&
    hasNumber(password)
  ) {
    strength = 'Medium';

    if (hasSpecialCharacters(password) && hasMinLengthOf(password, minimumStrongPasswordLength)) {
      strength = 'Strong';
    }
  }

  return strength;
};
