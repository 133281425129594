export const trackUser = () => {
  return !!document.cookie.split(';').filter((item) => {
    return item.indexOf('cookieconsent_status=dismiss') >= 0;
  }).length;
};

export const downloadFile = (url) => {
  const $frame = document.querySelector('#frame-download');

  if ($frame) {
    $frame.remove();
  }

  const frame = document.createElement('frame');

  frame.style.display = 'none';
  frame.id = 'frame-download';
  frame.src = url;
  document.body.appendChild(frame);
};

const RETRIES = 5;

const INTERVAL_MS = 1000;

export const lazyRetry = function (componentImport, retriesLeft = RETRIES, interval = INTERVAL_MS) {
  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => {
        resolve(component);
      })
      .catch((error) => {
        setTimeout(() => {
          // eslint-disable-next-line no-magic-numbers
          if (retriesLeft === 1) {
            reject(error);

            return;
          }
          // eslint-disable-next-line no-magic-numbers
          lazyRetry(componentImport, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};
