export const EMAILS_BLACK_LIST = new Set([
  'gmail.com',
  'hotmail.com',
  'hotmail.fr',
  'hotmail.it',
  'hotmail.uk',
  'yahoo.com',
  'outlook.com',
  'outlook.dk',
  'outlook.it',
  'outlook.ie',
  'qq.com',
  'icloud.com',
  'yandex.ru',
  'mail.ru',
  'me.com',
  'live.com',
  '163.com',
  'web.de',
  'protonmail.com',
  'googlemail.com',
  'wp.pl',
  'gmx.at',
  'gmx.de',
  'gmx.net',
  'aol.com',
]);
