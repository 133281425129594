import {useEffect} from 'react';
import {Userpilot} from 'userpilot';

import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {getIntercomProfileSelector} from '../../reducers/settings';

const USERPILOT_KEY = process.env.REACT_APP_USERPILOT;

const UserpilotUser = () => {
  const user = useSelector(getIntercomProfileSelector);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const {user_id, email, ...rest} = user;

    Userpilot.identify(
      email,
      {
        email,
        ...rest,
      }
    );
  }, [user]);

  return null;
};

const UserpilotReload = () => {
  const location = useLocation();

  useEffect(() => {
    Userpilot.reload();
  }, [location]);

  return null;
};

const UserpilotInitializer = () => {
  useEffect(() => {
    Userpilot.initialize(USERPILOT_KEY);
  }, []);

  return null;
};

const UserpilotWrapper = ({isAuthenticated}) => {
  if (!USERPILOT_KEY) return null;

  return (
    <>
      <UserpilotInitializer />
      {isAuthenticated && <UserpilotUser />}
      <UserpilotReload />
    </>
  );
};

export default UserpilotWrapper;
