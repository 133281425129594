/* eslint-disable no-shadow,camelcase */
import {getUrlWithToken} from '../auth';
import {FOLD_RATIO} from '../../constants/breakpoints';
import {RECOMMENDATION_STATUS} from '../../constants/generative';

const hundredPercentsValue = 100;

const sizeCloseToZero = 1;

export const determineStatus = (text, error) => {
  if (text && text.length > 0) return RECOMMENDATION_STATUS.SUCCESS;

  if (error && error.length > 0) return RECOMMENDATION_STATUS.ERROR;

  return RECOMMENDATION_STATUS.LOADING;
};

export const computeClarityFromString = (asHex, report = false, val = '') => {
  const v = val.toLowerCase();

  if (!report) {
    if (v.includes('simple') || v.includes('moderate')) {
      return asHex ? '#F7C137' : 'yellow';
    }

    if (v.includes('optimal')) {
      return asHex ? '#33AC2E' : 'green';
    }

    if (v.includes('severe')) {
      return asHex ? '#D63649' : 'red';
    }
  } else {
    if (v.includes('simple') || v.includes('moderate')) {
      return '#F3A861';
    }

    if (v.includes('optimal')) {
      return '#6DD2CE';
    }

    if (v.includes('severe')) {
      return '#FA7269';
    }
  }

  return asHex ? '#8798AD' : 'gray';
};

const removeOriginFromUrl = (url) => {
  const u = new URL(url);

  return url.replace(u.origin, '');
};

export const getDimensionsCurrentSlide = () => {
  const currentSlide = document.querySelector('.analysis-view-wrapper');

  if (!currentSlide) {
    return {slideWidth: 0, slideHeight: 0};
  }

  return {
    slideWidth: currentSlide.offsetWidth,
    slideHeight: currentSlide.offsetHeight
  };
};

export const getNaturalDimensionsCurrentSlide = () => {
  const currentSlide = document.querySelector('.analysis-view-wrapper img');

  if (!currentSlide) {
    return {slideWidth: 0, slideHeight: 0};
  }

  return {
    slideWidth: currentSlide.width,
    slideHeight: currentSlide.height
  };
};

export const getNaturalDimensionsImg = () => {
  const currentSlide = document.querySelector('.analysis-view-wrapper img');

  if (!currentSlide) {
    return {slideWidth: 0, slideHeight: 0};
  }

  return {
    slideWidth: currentSlide.width,
    slideHeight: currentSlide.height
  };
};

const FIXED_POINT = 1;

export const computeMeanAOI = ({data, dataFull}) => {
  // So lets just count by 4 at a time and always sum the value of the first one
  const incrementNumber = 4;

  let redCount = 0;
  for (let i = 0; i < data.length; i += incrementNumber) {
    redCount += data[i];
  }
  let fullRedCount = 0;
  for (let i = 0; i < dataFull.length; i += incrementNumber) {
    fullRedCount += dataFull[i];
  }

  // Supposedly the api will never send saliencyValue of 0
  return parseFloat(((redCount / fullRedCount) * hundredPercentsValue).toFixed(FIXED_POINT));
};
export const computeMaxAOI = ({data}) => {
  // So lets just count by 4 at a time and always sum the value of the first one
  const incrementNumber = 4;

  let maxValue = 0;
  for (let i = 0; i < data.length; i += incrementNumber) {
    if (data[i] > maxValue) {
      maxValue = data[i];
    }
  }
  const maxValueDivider = 255;

  return parseFloat(((maxValue / maxValueDivider) * hundredPercentsValue).toFixed(FIXED_POINT));
};

// Frontend AOI calculation logic (not used)
export const computeAOI = ({
  geometry, resource, max, type
}) => new Promise((resolve) => {
  // Append the auth token to the saliency url
  const saliencyUrl = getUrlWithToken(resource.saliencyUrl);

  // In order to determine the size of the invisible canvas, we have to compute
  // the dimensions of the currently shown slide
  const {slideHeight, slideWidth} = getNaturalDimensionsCurrentSlide();

  const slideRatio = slideWidth / slideHeight;

  const foldRatio = FOLD_RATIO[type];

  let foldHeight = slideHeight;

  if (foldRatio && slideRatio < foldRatio) {
    foldHeight = slideWidth / foldRatio;
  }

  const aoiVerticalPosition =
      (geometry.y * slideHeight + geometry.height * slideHeight) / hundredPercentsValue;

  if (foldHeight < aoiVerticalPosition) {
    throw Error('You couldn\'t create AOI below the fold');
  }

  // STEP 1 - Create an invisible canvas in which we gonna load the saliency image.
  // this canvas needs to keep the same dimentions as our current slide.
  const canvas = document.createElement('canvas');

  canvas.id = 'the-canvas';
  canvas.style.display = 'none';
  canvas.height = slideHeight;
  canvas.width = slideWidth;
  canvas.src = saliencyUrl;
  document.body.appendChild(canvas);

  // STEP 2 - Load the saliency image and draw it into the 2D canvas
  const img = new Image();

  img.crossOrigin = 'Anonymous';
  img.src = removeOriginFromUrl(saliencyUrl);

  img.onload = () => {
    const canvas = document.getElementById('the-canvas');

    const context = canvas.getContext('2d');

    // Draw the image to canvas.
    context.drawImage(img, 0, 0, slideWidth, slideHeight);

    // Calculate scaled sizes of the image
    const scaled_x = Math.round((geometry.x * slideWidth) / hundredPercentsValue);

    const scaled_y = Math.round((geometry.y * slideHeight) / hundredPercentsValue);

    const scaled_width = Math.round((geometry.width * slideWidth) / hundredPercentsValue)
      ? Math.round((geometry.width * slideWidth) / hundredPercentsValue)
      : sizeCloseToZero;

    const scaled_height = Math.round((geometry.height * slideHeight) / hundredPercentsValue)
      ? Math.round((geometry.height * slideHeight) / hundredPercentsValue)
      : sizeCloseToZero;

    // STEP 3 - Get all the pixels in the selected AOI area and sum them pixels.
    // get full saliencymap
    const dataFull = context.getImageData(0, 0, slideWidth, foldHeight).data;

    const {data} = context.getImageData(scaled_x, scaled_y, scaled_width, scaled_height);

    // Lets start counting them pixels, seems like we get a huge array in which
    // value 1 => red
    // value 2 => green
    // value 3 => blue
    // value 4 => alpha/white
    // value 5 => red
    // value 6 => green
    // ...
    let res;

    if (max === true) {
      res = computeMaxAOI({data});
    } else {
      res = computeMeanAOI({data, dataFull});
    }
    res = res > hundredPercentsValue ? hundredPercentsValue : res;

    // STEP 4 - Dispose the canvas and compute the proportion against the salValue
    document.getElementById('the-canvas').remove();
    resolve(res);
  };
});

// Calculate video prediction time (lenght in seconds, size in kb)
export const predictVideoProcessingTime = (videoLength, videoLimit) => {
  /*
  1 min or 60 sec of video = 600 sec of processing
  */
  // eslint-disable-next-line no-magic-numbers
  const processingSpeed = 900 / 60;

  const minProcessingTime = 60;

  const limitedLength = videoLength > videoLimit ? videoLimit : videoLength;

  const videoProcessingTime = Math.round((limitedLength * processingSpeed));

  return videoProcessingTime > minProcessingTime ? videoProcessingTime : minProcessingTime;
};
