import axios from '../../helpers/axios';

export default {
  getList: ({companyId, filters: params}) => axios.get(
    `/api/comparison/${companyId}/list`, {params}
  ),
  getComparison: (comparisonId) => axios.get(`/api/comparison/${comparisonId}`),
  // eslint-disable-next-line max-len
  create: ({companyId, studies, name}) => axios.post(`/api/comparison/${companyId}`, {studies, name}),
  save: ({id, name, studies}) => axios.put(
    `/api/comparison/${id}`, {name, studies}
  ),
  deleteById: (comparisonId) => axios.delete(`/api/comparison/${comparisonId}`),
  getImage: () => {},
  uploadImage: ({
    id, screenType, formData
  }) => axios.post(
    `/api/comparison/${id}/upload-image`,
    {formData, analysisType: 'web', screenType},
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
  ),
};
