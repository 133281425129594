import {LOCAL_STORAGE_KEYS} from '../../constants/localStorage';

export const clearLocalStorage = () => {
  const savedValues = {};

  Object.values(LOCAL_STORAGE_KEYS).forEach((storageKey) => {
    const value = localStorage.getItem(storageKey);

    if (value) {
      savedValues[storageKey] = value;
    }
  });

  localStorage.clear();

  Object.entries(savedValues).forEach(([key, value]) => {
    localStorage.setItem(key, value);
  });
};
