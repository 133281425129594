import axios from '../../helpers/axios';

export default {
  getInvitationRemoved: ({inviteId}) => axios.delete(
    `/api/companies/members/invite/${inviteId}`
  ),
  getInvitations: ({companyId}) => axios.get(
    `/api/companies/${companyId}/members`
  ),
  postCreateInvitation: ({companyId, email}) => axios.post(
    `/api/companies/members/${companyId}/invite`, {email}
  ),
  postBuySeats: ({companyId, seats}) => axios.post(
    `/api/companies/members/${companyId}/buy/seats`, {seats}
  ),
  postReduceSeats: ({companyId, seats}) => axios.post(
    `/api/companies/members/${companyId}/reduce/seats`, {seats}
  ),
  acceptInvitation: ({token}) => axios.get(`/api/companies/accept/${token}`),
  removeTeamMember: ({userId, companyId}) => axios.delete(
    `/api/companies/${companyId}/members/${userId}`
  ),
  getCompanyUsers: ({companyId}) => axios.get(
    `/api/companies/${companyId}/users`
  ),
};
