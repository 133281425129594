import Pusher from 'pusher-js';
import {get} from 'lodash';
import * as Sentry from '@sentry/browser';
import {
  updateResource,
  updateStudyStatus,
  addScrapedUrl,
  stopCounting,
  setProgress,
  addGenerativeRecommendation,
  showGenerativeRecommendation,
  setLoadingError,
  addAoiGenerativeRecommendation,
  showGenerativeAoiRecommendation
} from '../../reducers/studies';
import {getUrlWithToken, getCorrelationId} from '../../helpers/auth';
import {downloadFile} from '../../utils/browser';
import history from '../../helpers/router/history';
import Api from '../api';
import {NEW_ANALYSIS} from '../../constants/routes';
import {fetchUserProfile} from '../../reducers/settings';
import {toast} from '../../utils/toast';

const PUSHER_KEY = process.env.REACT_APP_PUSHER;

const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;

const IS_DEV = process.env.NODE_ENV === 'development';

// This little guy will hold an instance to our currently open channel.
let channel;

const pusher = new Pusher(PUSHER_KEY, {
  cluster: PUSHER_CLUSTER,
  forceTLS: !IS_DEV
});

const processDoneValue = 100;

// set socket
export const subscribeToEvents = (user, store) => {
  if (!user) {
    return;
  }

  channel = pusher.subscribe(`App.User.${user.id}`);

  channel.bind('resource.focusmap', async ({resourceId}) => {
    try {
      const res = await Api.studies.getResourceDetails({id: resourceId});

      const resource = get(res, 'data.resources.data', []);

      store.dispatch(updateResource({data: resource, id: resourceId}));
      resource.forEach((resourceItem) => {
        if (resourceItem.focus_image) {
          const img = new Image();

          img.src = getUrlWithToken(resourceItem.focus_image);
        }
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  });

  channel.bind('resource.contrastmap', async ({resourceId}) => {
    try {
      const res = await Api.studies.getResourceDetails({id: resourceId});

      const resource = get(res, 'data.resources.data', []);

      store.dispatch(updateResource({data: resource, id: resourceId}));
      resource.forEach((resourceItem) => {
        if (resourceItem.contrast_image) {
          const img = new Image();

          img.src = getUrlWithToken(resourceItem.contrast_image);
        }
      });

      // Replace with credits only call
      store.dispatch(fetchUserProfile());
    } catch (err) {
      Sentry.captureException(err);
    }
  });

  channel.bind('resource.generative.recommendations', async ({generativeId}) => {
    try {
      const recommendation = await Api.generative.getRecommendation({generativeId});

      const {data} = recommendation;

      if (data?.aoiId) {
        store.dispatch(addAoiGenerativeRecommendation({data}));
        store.dispatch(showGenerativeAoiRecommendation(data.aoiId));
      } else {
        store.dispatch(addGenerativeRecommendation({data}));
        store.dispatch(showGenerativeRecommendation(data?.generativeId));
      }

      // Replace with credits only call
      store.dispatch(fetchUserProfile());
    } catch (err) {
      Sentry.captureException(err);
    }
  });

  channel.bind('resource.failed.generative.recommendations', async ({generativeId, error, category}) => {
    try {
      const data = {generativeId, error, category};

      // save state, to avoid additional request
      store.dispatch(addGenerativeRecommendation({data}));

      store.dispatch(showGenerativeRecommendation(data?.generativeId));
    } catch (err) {
      Sentry.captureException(err);
    }
  });

  channel.bind('resource.processed', async ({resourceId}) => {
    try {
      const res = await Api.studies.getResourceDetails({id: resourceId});

      // API returns an array of resources since if an image is too long it will
      // be cropped into many
      const resource = get(res, 'data.resources.data', []);

      store.dispatch(updateResource({data: resource, id: resourceId}));
      store.dispatch(fetchUserProfile());
      // Pre-download processed image heatmap
      resource.forEach((resourceItem) => {
        if (resourceItem.heatmapUrl) {
          const img = new Image();

          img.src = getUrlWithToken(resourceItem.heatmapUrl);
        }
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  });

  channel.bind('study.processed', async ({studyId}) => {
    store.dispatch(updateStudyStatus({studyId}));
  });

  channel.bind('resource.download', ({url, deviceId}) => {
    const tokenizedUrl = getUrlWithToken(url);

    if (deviceId === getCorrelationId()) {
      downloadFile(tokenizedUrl);
    }
  });

  channel.bind('study.zip', ({zipUrl, deviceId}) => {
    const url = getUrlWithToken(zipUrl);

    if (deviceId === getCorrelationId()) {
      downloadFile(url);
    }
  });

  channel.bind('study.failed.zip', () => {
    toast.error('Failed to generate zip file. Please try again later.');
  });

  channel.bind('resource.scrapped', async ({resourceId, studyId, url}) => {
    try {
      if (studyId && url == null) {
        store.dispatch(setLoadingError(studyId));
        toast.error('Failed to screenshot the URL. Website is not accessible or blocking access.');
      } else if (resourceId && url) {
        const res = await Api.studies.getResourceDetails({id: resourceId});

        const resource = get(res, 'data.resources.data', []);

        const isEditMode = history.location.pathname.startsWith(NEW_ANALYSIS);

        if (resource.length && resource[0].statusKey && resource[0].statusKey === 'scrapped') {
          store.dispatch(addScrapedUrl({resource, isEditMode}));
          store.dispatch(stopCounting());
          store.dispatch(setProgress(processDoneValue));
        } else {
          store.dispatch(stopCounting());
          store.dispatch(setProgress(processDoneValue));
          toast.error('Failed to screenshot the URL. Website is not accessible or blocking access.');
        }
      }
    } catch (err) {
      toast.error('Failed to screenshot the URL. Please try again later.');
      Sentry.captureException(err);
    }
  });
};

export function getImageUrlFromPusher (url) {
  return fetch(url)
    .then((result) => result.json())
    .then(({channel: pusherChannel}) => {
      return new Promise((resolve) => {
        subscribeOnceWithCallback(pusherChannel, resolve);
      });
    });
}

export function subscribeOnceWithCallback (channelName, callback) {
  const oneTimeChannel = pusher.subscribe(channelName);

  oneTimeChannel.bind('resource.download', ({url}) => {
    if (callback) {
      callback(url);
    }

    pusher.unsubscribe(channelName);
  });
}

export function subscribeOnce (channelName) {
  const oneTimeChannel = pusher.subscribe(channelName);

  oneTimeChannel.bind('resource.download', ({url}) => {
    const tokenizedUrl = getUrlWithToken(url);

    downloadFile(tokenizedUrl);

    pusher.unsubscribe(channelName);
  });

  oneTimeChannel.bind('study.zip', ({zipUrl}) => {
    const url = getUrlWithToken(zipUrl);

    downloadFile(url);
    pusher.unsubscribe(channelName);
  });

  oneTimeChannel.bind('study.failed.zip', () => {
    toast.error('Failed to generate zip file. Please try again later.');
    pusher.unsubscribe(channelName);
  });
}

export const unsubscribeToEvents = () => {
  if (
    !pusher ||
    typeof pusher.allChannels !== 'function' ||
    typeof pusher.unsubscribe !== 'function'
  ) {
    console.error('Pusher is not properly initialized or the necessary methods are missing.');

    return;
  }

  const channels = pusher.allChannels();

  if (!Array.isArray(channels)) {
    console.error('Expected channels to be an array.');

    return;
  }

  channels.forEach((c) => {
    if (c && typeof c.name === 'string') {
      pusher.unsubscribe(c.name);
    }
  });
};
