import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const isBlacklistedUrl = (location) => {
  // FACEBOOK_CALLBACK, GOOGLE_CALLBACK - removed as user with Oauth doesnt get in Intercom DB
  const blacklisted = ['/auth/demo', '/single-analysis/share', '/single-video-analysis/share'];

  return blacklisted.some((e) => location.startsWith(e) && location !== '/');
};

export const ScrollToTop = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
