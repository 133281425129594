import axios from '../../helpers/axios';

export default {
  getProfile: () => axios.get('/api/profile'),
  changePassword: ({current, password, password2}) => axios.post('/api/change-password', {
    old_password: current,
    password,
    password_confirmation: password2,
  }),
  updateProfile: (profile) => axios.put('/api/profile', profile),
  deleteAccount: () => axios.delete('/api/user'),
  revokeApi: () => axios.delete('/api/api-client'),
  createApi: () => axios.get('/api/api-client'),
};
