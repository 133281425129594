import {createAction, createReducer} from '@reduxjs/toolkit';
import {get} from 'lodash';
import {toast} from 'react-toastify';

import Api from '../../services/api';

export const setReponse = createAction('DEMO/SET_RESPONSE');

const initialState = {
  response: false
};

export default createReducer(initialState, {
  [setReponse]: (state, action) => {
    // eslint-disable-next-line no-param-reassign
    state.response = action.payload;
  }
});

// Not used functionality. Disabled on backend too 2024.03.26
export const startDemo = ({email, file, setSuccess}) => async () => {
  try {
    /* Send demo request */

    const formData = new FormData();

    formData.append('file', file);
    formData.append('email', email);

    await Api.demo.startDemo(formData);

    // toast.success('You will get results after few minutes to your email!');
    setSuccess(true);
  } catch (e) {
    const {data} = e.response;

    const errors = get(data, 'errors', {general: 'Couldn\'t process demo'});

    const errorsList = Object.values(errors);

    for (let i = 0; i < errorsList.length; i++) {
      for (let j = 0; j < errorsList[i].length; j++) {
        toast.error(errorsList[i][j]);
      }
    }

    return errors;
  }

  return [];
};
