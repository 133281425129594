/* eslint-disable no-shadow,no-param-reassign */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Api from '../../services/api';
import history from '../router/history';
import {unprocessableEntityStatusCode} from '../../constants/responseCodes';
import {clearLocalStorage} from '../storage';

const axiosInstance = axios.create();

function getAccessToken () {
  return localStorage.getItem('token');
}

axiosInstance.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${getAccessToken()}`;

  return request;
});

function parseReq (req) {
  let r = {
    error_description: undefined
  };
  try {r = JSON.parse(req.response);} catch {
    return;
  }

  return r;
}

// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest) => {
  const res = parseReq(failedRequest.request);

  if (res.error_description && failedRequest.request.responseURL.includes('/oauth/token')) {
    return Promise.resolve(failedRequest);
  }

  return Api.auth.refreshToken().then((res) => {
    const {access_token: token, refresh_token} = res.data;

    localStorage.setItem('token', token);
    localStorage.setItem('refresh_token', refresh_token);

    failedRequest.response.config.headers.Authentication = `Bearer ${token}`;

    return Promise.resolve();
  }).catch(() => {
    history.push('/auth/logout');
    clearLocalStorage();

    return Promise.reject();
  });
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {pauseInstanceWhileRefreshing: true});

// If we ever get a 422, redirect to /auth/verify_email flow
axiosInstance.interceptors.response.use(null, (error) => {
  if (error?.response?.status === unprocessableEntityStatusCode) {
    const responseData = error.response.data;

    if (!responseData.success && responseData.errors?.user) {
      if (!window.location.pathname.includes('/auth/verify_email/')) {
        history.push('/auth/verify_email');
      }
    }
  }

  return Promise.reject(error);
});

export default axiosInstance;
