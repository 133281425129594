import {useEffect, useState} from 'react';
import {compose} from 'redux';
import ReactGA from 'react-ga4';
import {withRouter} from 'react-router-dom';

const GA4_KEY = process.env.REACT_APP_WITH_GA4_ID;

const TrackingInterfaceGA4 = ({isAuthenticated, location}) => {
  const [loaded, setLoaded] = useState({track: false});

  useEffect(() => {
    if (GA4_KEY && !loaded.track) {
      ReactGA.initialize(GA4_KEY);
      setLoaded({...loaded, track: true});
    }
  }, [loaded, isAuthenticated, location]);

  return null;
};

export const GA4_EVENTS = {
  CREATE_AOI: {name: 'SingleAnalysis_Aoi', category: 'single_analysis', action: 'created'},
  EDIT_AOI: {name: 'SingleAnalysis_AoiEdit', category: 'single_analysis', action: 'updated'},
  COPY_AOIS: {name: 'SingleAnalysis_AoisCopy', category: 'single_analysis', action: 'click'},
  DELETE_AOIS: {name: 'SingleAnalysis_AoisDelete', category: 'single_analysis', action: 'click'},
  DOWNLOAD_AOIS: {name: 'SingleAnalysis_AoisDownload', category: 'single_analysis', action: 'click'},
  DOWNLOAD_ALL: {name: 'SingleAnalysis_DownloadAll', category: 'single_analysis', action: 'click'},
  DOWNLOAD_CURRENT: {name: 'SingleAnalysis_DownloadCurrent', category: 'single_analysis', action: 'click'},
  MOVE_VIEWPORT: {name: 'SingleAnalysis_MovewViewport', category: 'single_analysis', action: 'created'},
  REPORT_PROJECT_PDF: {name: 'Project_Report-PDF', category: 'single_analysis', action: 'created'},
  REPORT_PDF: {name: 'SingleAnalysis_Report-PDF', category: 'single_analysis', action: 'created'},
  REPORT_PROJECT_PPT: {name: 'SingleAnalysis_ReportProject-PPT', category: 'single_analysis', action: 'created'},
  REPORT_PPT: {name: 'SingleAnalysis_Report-PPT', category: 'single_analysis', action: 'created'},
  SHARE_PUBLIC: {name: 'SingleAnalysis_SharePublic', category: 'single_analysis', action: 'created'},
  SHARE_TEAM: {name: 'SingleAnalysis_ShareTeam', category: 'single_analysis', action: 'created'},
  SIDEBAR_GENERATIVE: {name: 'SingleAnalysis_Sidebar-Generative', category: 'single_analysis', action: 'clicked'},
  ZOOM_IN: {name: 'SingleAnalysis_Zoom', category: 'single_analysis', action: 'zoomIn'},
  ZOOM_OUT: {name: 'SingleAnalysis_Zoom', category: 'single_analysis', action: 'zoomOut'},
  ZOOM_RESET: {name: 'SingleAnalysis_Zoom', category: 'single_analysis', action: 'reset'},
  TOGGLE_AOI: {name: 'SingleAnalysis_ToggleAoi', category: 'single_analysis', action: 'click'},
  TOGGLE_VIEWPORT: {name: 'SingleAnalysis_ToggleViewport', category: 'single_analysis', action: 'click'},
  CONTRAST_MAP: {name: 'SingleAnalysis_ContrastMap', category: 'single_analysis', action: 'click'},
  FOCUS_MAP: {name: 'SingleAnalysis_FocusMap', category: 'single_analysis', action: 'click'},
  ORIGINAL_IMAGE: {name: 'SingleAnalysis_OriginalImage', category: 'single_analysis', action: 'click'},
  SEARCH: {name: 'SingleAnalysis_SearchOpen', category: 'single_analysis', action: 'click'},
  ERROR_PAGE: {name: 'App_ErrorPage', category: 'error_page', action: 'open'},
  ANALYSIS_PAGE: {name: 'SingleAnalysis_Open', category: 'single_analysis', action: 'open'},
  ANALYSIS_DEMO_PAGE: {name: 'SingleAnalysis_DemoOpen', category: 'single_analysis', action: 'open'},

};

export const triggerGA4Event = (event) => {
  const {name, category, action} = event;

  if (name && category && action) {
    ReactGA.event(name, {
      category,
      action,
      nonInteraction: true, // optional, true/false
      transport: 'xhr', // optional, beacon/xhr/image
    });
  }
};

const enhance = compose(withRouter);

export default enhance(TrackingInterfaceGA4);
