import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';

import settings from './settings';
import billing from './billing';
import studies from './studies';
import comparisons from './comparisons';
import projects from './projects';
import createComparison from './createComparison/reducer';
import studiesById from './studiesById/reducer';
import demo from './demo';
import reports from './reports';
import header from './header';

const reducers = {
  settings,
  billing,
  studies,
  comparisons,
  projects,
  createComparison,
  studiesById,
  demo,
  reports,
  header,
};

const rootReducer = combineReducers(reducers);

// Reset state to initial if `AUTH/LOGOUT` action ever got dispatched
export default (state, action) => {
  if (action.type === 'AUTH/LOGOUT') {
    storage.removeItem('persist:root');

    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};
