import {Component} from 'react';
import * as Sentry from '@sentry/react';
import './styles.scss';
import {GA4_EVENTS, triggerGA4Event} from '../UserTrackingGA4';

class ErrorBoundary extends Component {
  constructor (props) {
    super(props);
    this.state = {hasError: false, error: null};
  }

  static getDerivedStateFromError (error) {
    return {hasError: true, error};
  }

  componentDidCatch (error, errorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
    this.setState({hasError: true, error});

    try {
      // Send error to Sentry
      Sentry.captureException(error, {extra: errorInfo});
      triggerGA4Event(GA4_EVENTS.ERROR_PAGE);
    } catch (sentryError) {
      console.error('Error while reporting error', sentryError);
    }
  }

  render () {
    if (this.state.hasError) {
      const isChunkLoadError = this.state.error?.message && this.state.error?.message.includes('ChunkLoadError');

      if (isChunkLoadError) {
        return (
          <div className='error-boundary'>
            <h1 className='error-boundary__title'>
              Something went wrong. Probably the application has been updated. Please reload the page.
            </h1>
            <button
              type="button"
              className='error-boundary__btn'
              onClick={() => window.location.reload()}
            >
              Reload
            </button>
          </div>
        );
      }

      return (
        <div className='error-boundary'>
          <h1 className='error-boundary__title'>
            Something went wrong. If the problem persists, please contact support@attentioninsight.com.
          </h1>
          <button
            type="button"
            className='error-boundary__btn'
            onClick={() => window.history.back()}
          >
              Go back
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
