import {createContext, useContext} from 'react';
import {useMobileView} from '../utils/Hooks/index';

const MobileViewContext = createContext();

export const ViewportProvider = ({children}) => {
  const viewport = useMobileView();

  return (
    <MobileViewContext.Provider value={viewport}>
      {children}
    </MobileViewContext.Provider>
  );
};

export const useMobileViewContext = () => useContext(MobileViewContext);
